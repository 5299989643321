import { create } from 'zustand';

import { defaultCardDetails, payerAuthStatus } from '@helper/constants';
import DebugConsole from '@helper/functions/console';
import { getBrowserInfo } from '@helper/functions/device';
import {
  getMaskedValueCCFromJWT,
  getMicroformErrorMessage,
  getPayerAuthEnrolmentErrorMessage,
  getTransientTokenFromJWT,
  getValidatePayerAuthErrorMessage,
} from '@helper/functions/flex';
import { postGenerateReceipt, sendRating, sendReceiptEmail } from '@helper/service/invoice';
import * as PaymentService from '@helper/service/payment';
import * as RecaptchaService from '@helper/service/recaptcha';

import { useDialogStore } from './dialog';
import useNavStore from '@store/nav';
export const recognisedPaymentTypes = ['sd', 'enable', 'donation', 'misc'/*, 'pbrc'*/];
export const recognisedPaymentMethods = ['creditCard', 'cash', 'cheque'];

export const useActiveTransactionStore = create((set, get) => ({
  activeTx: {},
  activeStep: 'step1',
  setActiveStep: (step) => set({ activeStep: step }),

  updateActiveTx: (entries) => {
    set({
      activeTx: { ...get().activeTx, ...entries },
    });
  },

  paymentMethod: '',
  setPaymentMethod: (newMethod) => {
    set({
      paymentMethod: newMethod,
      activeTx: { ...get().activeTx, paymentMethod: newMethod },
    });
  },

  setPaymentAmount: (amount) => {
    set({
      activeTx: { ...get().activeTx, amount: parseFloat(amount).toFixed(2) },
    });
  },

  setCashData: (data) => {
    set({
      activeTx: {
        ...get().activeTx,
        cash: {
          //paidBy: data.paidBy,
          amountChange: data.amountChange,
          amountTendered: data.amountTendered,
          amountPayable: data.amountPayable,
        },
      },
    });
  },

  setChequeData: (data) => {
    set({
      activeTx: {
        ...get().activeTx,
        cheque: {
          drawer: data.drawer,
          chequeNumber: data.chequeNumber,
          accountNumber: data.accountNumber,
          bsb: data.bsb,
        },
      },
    });
  },

  setTerminalData: (data) => {
    set({
      activeTx: {
        ...get().activeTx,
        terminal: {
          paymentDate: data.paymentDate,
          paymentTime: data.paymentTime,
          terminalID: data.terminalID,
          merchantID: data.merchantID,
          sequenceNumber: data.sequenceNumber,
          comments: data.comments,
        },
      },
    });
  },

  setTerminalDataBulk: (data) => {
    set({
      activeTx: {
        ...get().activeTx,
        terminalBulk: {
          paymentDateFrom: data.paymentDateFrom,
          paymentDateTo: data.paymentDateTo,
          terminalID: data.terminalID,
          merchantID: data.merchantID,
          comments: data.comments,
        },
      },
    });
  },

  resetTransaction: () => {
    set({ activeTx: {}, activeStep: 'step1', paymentMethod: '', onSuccessCallback: undefined });
  },

  /**
   * Initialise a transaction
   */
  initTransaction: (
    {
      paymentType = 'misc',
      merchantReference = '',
      referencePrefix = '',
      amount = '0.00',
      allowCustomAmount = false,
      healthEntityCode = '',
      payerAuthCode,
      billingFirstName = '',
      billingLastName = '',
      billingCustomerFullName = '',
      billingEmail = '',
      formData,
      confirmInfoFields = [],
    },
    onSuccessCallback,
  ) => {
    let data = {
      paymentType,
      merchantReference,
      referencePrefix,
      payerAuthCode,
      amount: parseFloat(amount).toFixed(2),
      allowCustomAmount,
      healthEntityCode,
      billingEmail,
      billingFirstName,
      billingLastName,
      billingCustomerFullName,
      formData: { ...formData },
      confirmInfoFields: [...confirmInfoFields],
    };

    set({
      activeTx: data,
      onSuccessCallback,
    });

    useDialogStore.getState().handlePaymentDialog(true);
  },

  onSuccessCallback: undefined,

  handlePaymentSuccess: async () => {
    usePaymentStore.getState().resetValidatePayerAuthData();
    if (get().onSuccessCallback && typeof get().onSuccessCallback === 'function') {
      get().onSuccessCallback();
      set({ onSuccessCallback: undefined });
    }
  },
}));

export const usePaymentStore = create((set, get) => ({
  // Card details
  maskedValueCC: '',
  cardDetails: {},
  setCardDetails: (values) => {
    return set((state) => ({
      cardDetails: { ...state.cardDetails, ...values },
    }));
  },

  cardType: '',
  setCardType: (values) => {
    return set({ cardType: values });
  },

  // Generate microform capture context
  captureContext: undefined,
  generateFlexCaptureContext: async (callback) => {
    const activeTx = useActiveTransactionStore.getState().activeTx;

    const healthEntityCode = activeTx.healthEntityCode;
    const paymentType = activeTx.paymentType;

    let captureContext;
    console.log("Print payment type: ", paymentType)
    // if(paymentType === 'pbrc'){
    //   captureContext = await PaymentService.getGenerateCaptureContext(
    //     healthEntityCode,
    //     paymentType,
    //     true
    //   );
    // }else{
      captureContext = await PaymentService.getGenerateCaptureContext(
        healthEntityCode,
        paymentType,
      );
    //}
    if (!captureContext) return callback(false);

    set({
      captureContext,
    });
    callback(captureContext);
  },

  // Flex transient toke
  transientTokenJWT: '',
  transientTokenError: {},
  generateFlexTransientToken: async (microform, recaptchaToken, isRecaptchaEnabled, callback) => {
    let recaptchaResponse = null;
    if(isRecaptchaEnabled){
      recaptchaResponse = await RecaptchaService.postValidateRecaptcha(recaptchaToken);
    }
    if (isRecaptchaEnabled && !recaptchaResponse?.success) return callback(false);

    const { cardDetails } = get();

    const { expiry, cardType } = cardDetails;
    const [expiryMonth, expiryYear] = expiry.split('/');

    const currentYearStartingYY = new Date().getFullYear().toString().slice(0, 2);
    const requestOptions = {
      type: cardType,
      expirationMonth: expiryMonth,
      expirationYear: `${currentYearStartingYY}${expiryYear}`,
    };

    try {
      microform.createToken(requestOptions, (error, token) => {
        if (error) {
          const { messageLocation } = getMicroformErrorMessage(error);

          set({
            transientTokenError: messageLocation,
            showPaymentFormErrors: true,
          });
          return callback(false);
        }

        set({ transientTokenJWT: token });
        set({ maskedValueCC: getMaskedValueCCFromJWT(token) });

        callback(true);
      });
    } catch (err) {
      DebugConsole.error('Could not request flex microform transient token');
      DebugConsole.error(err);

      return callback(false);
    }
  },

  // Process payment
  isPaymentProcessing: false,
  paymentRes: null,
  paymentError: null,
  showPaymentFormErrors: false,
  processPayment: async (user, callback) => {
    set({ isPaymentProcessing: true });

    const activeTx = useActiveTransactionStore.getState().activeTx;
    const paymentMethod = useActiveTransactionStore.getState().paymentMethod;

    if (['cash', 'cheque', 'terminal'].includes(paymentMethod)) {
      // Payment service payload
      const payload = {
        reference: activeTx.merchantReference || activeTx.paymentType,
        referencePrefix: activeTx.referencePrefix,
        paymentType: activeTx.paymentType,
        paymentMethod: paymentMethod,
        amount: activeTx.amount,
        jwtToken: 'CASHIER_PAYMENT',
        paConsumerAuthInformation: {},
        activeInvoice: { ...activeTx, paymentMethod: paymentMethod },
        healthEntityCode: activeTx.healthEntityCode,
        userName: user.isLoggedIn && user.Roles.includes('cashier') ? user.UserName : '',
        userFullName: user.isLoggedIn && user.Roles.includes('cashier') ? user.UserFullName : '',
        userEmail: user.isLoggedIn && user.Roles.includes('cashier') ? user.UserEmail : '',
      };

      const paymentType = activeTx.paymentType;
      let paymentRes;
      console.log("Print payment type: ", paymentType)
      // if(paymentType === 'pbrc'){
      //   paymentRes = await PaymentService.postFlexProcessPayment(payload,true);
      // }else{
        paymentRes = await PaymentService.postFlexProcessPayment(payload);
      //}

      if (!paymentRes) return set({ paymentError: 'Server failed to process the payment' });

      const { paymentData, invoiceData } = paymentRes;

      set({ isPaymentProcessing: false });
      set({ isRatingSubmitted: false });

      // handle payment errors and return
      if (!paymentData) return set({ paymentError: 'Server failed to process the payment' });
      if ('errorInformation' in paymentData)
        return set({ paymentError: paymentData?.errorInformation?.message });

      // reset payment state
      set({ paymentRes, cardDetails: { ...defaultCardDetails }, paymentError: null });

      // trigger any success callback
      if (callback) {
        callback();
      }

      return;
    }

    const { transientTokenJWT, cardDetails, enrollData, validatePayerAuthData } = get();

    const amount = parseFloat(cardDetails.amount).toFixed(2);
    const paConsumerAuthInformation = validatePayerAuthData
      ? validatePayerAuthData.consumerAuthenticationInformation
      : enrollData.consumerAuthenticationInformation;

    // Payment service payload
    const payload = {
      reference: activeTx.merchantReference || activeTx.paymentType,
      referencePrefix: activeTx.referencePrefix,
      paymentType: activeTx.paymentType,
      paymentMethod: 'creditCard',
      amount,
      jwtToken: transientTokenJWT,
      paConsumerAuthInformation,
      activeInvoice: activeTx,
      healthEntityCode: activeTx.healthEntityCode,
      userName: user.isLoggedIn && user.Roles.includes('cashier') ? user.UserName : '',
      userFullName: user.isLoggedIn && user.Roles.includes('cashier') ? user.UserFullName : '',
      userEmail: user.isLoggedIn && user.Roles.includes('cashier') ? user.UserEmail : '',
    };

    const paymentType = activeTx.paymentType;

    let paymentRes;
    console.log("Print payment type: ", paymentType)
    // if(paymentType === 'pbrc'){
    //   paymentRes = await PaymentService.postFlexProcessPayment(payload, true);
    // }else{
      paymentRes = await PaymentService.postFlexProcessPayment(payload);
   // }

    if (!paymentRes)
      return set({
        paymentError: 'Server failed to process the payment',
        isPaymentProcessing: false,
      });

    const { paymentData, invoiceData } = paymentRes;

    set({ isPaymentProcessing: false });
    set({ isRatingSubmitted: false });

    // handle payment errors and return
    if (!paymentData) return set({ paymentError: 'Server failed to process the payment' });
    if ('errorInformation' in paymentData)
      return set({ paymentError: paymentData?.errorInformation?.message });

    // reset payment state
    set({ paymentRes, cardDetails: { ...defaultCardDetails }, paymentError: null });

    // trigger any success callback
    if (callback) {
      callback();
    }
  },

  // Payer auth
  isPayerAuthenticating: false,
  payerAuthData: null,
  payerAuthError: null,
  initPayerAuthentication: async ({ onSuccess = () => { } }) => {
    set({ isPayerAuthenticating: true });

    const { transientTokenJWT } = get();

    const transientToken = getTransientTokenFromJWT(transientTokenJWT);
    const activeTx = useActiveTransactionStore.getState().activeTx;
    const payload = {
      code: activeTx.payerAuthCode,
      transientToken,
      healthEntityCode: activeTx.healthEntityCode,
      paymentType: activeTx.paymentType,
    };

    const paymentType = activeTx.paymentType;
    console.log("Print payment type: ", paymentType)
    let payerAuthData;
    // if(paymentType === 'pbrc'){
    //   payerAuthData = await PaymentService.postSetupPayerAuth(payload, true);
    // }else{
      payerAuthData = await PaymentService.postSetupPayerAuth(payload);
   // }

    
    console.log('payerAuthData: ', payerAuthData);
    // handles payer authentication error message
    if (!payerAuthData) {
      set({
        isPayerAuthenticating: false,
        payerAuthError: 'Decline - Invalid account number',
      });

      set({ payerAuthData: null });

      return;
    }

    set({ payerAuthData });
    onSuccess();
  },

  // Payer auth enrolment check
  enrollData: null,
  isPayerAuthIFrameEnabled: false,
  initPayerAuthEnrollmentCheck: async ({ onSuccess }) => {
    const { transientTokenJWT, cardDetails, payerAuthData } = get();

    const amount = parseFloat(cardDetails.amount).toFixed(2);
    const transientToken = getTransientTokenFromJWT(transientTokenJWT);
    const activeTx = useActiveTransactionStore.getState().activeTx;
    const code = activeTx.payerAuthCode;
    const healthEntityCode = activeTx.healthEntityCode;
    const paymentType = activeTx.paymentType;
    const referenceId = payerAuthData.consumerAuthenticationInformation.referenceId;
    // const deviceInformation = getBrowserInfo();

    const payload = {
      transientToken,
      code,
      amount,
      referenceId,
      healthEntityCode,
      paymentType,
      // deviceInformation,
    };
    console.log("Print payment type: ", paymentType)
    let enrollData;
    // if(paymentType === 'pbrc'){
    //   // @ts-ignore device info is not included yet
    //   enrollData = await PaymentService.postCheckPayerAuthEnrollment(payload, true);
    // }else{
      // @ts-ignore device info is not included yet
      enrollData = await PaymentService.postCheckPayerAuthEnrollment(payload);
    //}

    // handles payer auth enrolment error message
    if (!enrollData)
      return set({
        enrollData: null,
        isPayerAuthenticating: false,
        payerAuthError: 'Failed to check enrolment',
      });

    set({ enrollData, isPayerAuthenticating: false });

    // @ts-ignore
    const status = enrollData.status;

    if (status === payerAuthStatus.success) {
      onSuccess();
    } else if (status === payerAuthStatus.pending) {
      set({ isPayerAuthIFrameEnabled: true });
    } else if (status === payerAuthStatus.failed) {
      const errorMessage = getPayerAuthEnrolmentErrorMessage(enrollData);
      set({ payerAuthError: errorMessage });
    }
  },

  // Validate payer auth
  validatePayerAuthData: null,
  initValidatePayerAuth: async ({ transactionId, onSuccess }) => {
    const enrollData = get().enrollData;
    const paConsumerAuthInformation = enrollData.consumerAuthenticationInformation;

    const activeTx = useActiveTransactionStore.getState().activeTx;

    const payload = {
      transactionId,
      paConsumerAuthInformation,
      healthEntityCode: activeTx.healthEntityCode,
      paymentType: activeTx.paymentType,
      code: activeTx.payerAuthCode || activeTx.merchantReference,
    };

    const paymentType = activeTx.paymentType
    let validatePayerAuthData;
    console.log("Print payment type: ", paymentType)
    // if(paymentType === 'pbrc'){
    //   validatePayerAuthData = await PaymentService.postValidatePayerAuth(payload);
    // }else{
      validatePayerAuthData = await PaymentService.postValidatePayerAuth(payload);
   // }

    console.debug(`validate-payer-auth`, validatePayerAuthData);

    if (!validatePayerAuthData) {
      set({
        payerAuthError: 'Payer validation service could not process the request',
        isPayerAuthIFrameEnabled: false,
      });
      return;
    }

    const status = validatePayerAuthData.status;

    set({ validatePayerAuthData });

    if (status === payerAuthStatus.success) {
      onSuccess();
    } else if (status === payerAuthStatus.failed) {
      const errorMessage = getValidatePayerAuthErrorMessage(validatePayerAuthData);

      set({ payerAuthError: errorMessage, isPayerAuthIFrameEnabled: false });
    }
  },

  // Generate receipt
  isReceiptLoading: false,
  generateReceiptPDF: async (callback) => {
    set({ isReceiptLoading: true });

    const { paymentData, invoiceData } = get().paymentRes;
    const { paymentMethod, paymentType, ...invoiceFields } = invoiceData;

    const body = {
      invoice: {
        ...invoiceFields,
        maskedValueCC: get().maskedValueCC || '',
        cardType: get().cardType || '',
      },
      paymentResponse: {
        id: paymentData.id,
        transactionId: paymentData.processorInformation.transactionId,
        submitTimeUtc: paymentData.submitTimeUtc.toLocaleString(),
        authorizedAmount: paymentData.orderInformation.amountDetails.authorizedAmount,
        status: paymentData.status,
        retrievalReferenceNumber: paymentData.processorInformation.retrievalReferenceNumber,
      },
      paymentType: paymentType,
      paymentMethod: paymentMethod,
    };
    let receiptBuffer;
    console.log("Print payment type: ", paymentType)
    // if(paymentType === 'pbrc'){
    //   receiptBuffer = await postGenerateReceipt(body, true);
    // }else{
      receiptBuffer = await postGenerateReceipt(body);
   // }

    set({ isReceiptLoading: false });
    if (receiptBuffer) return callback(receiptBuffer);
  },

  //Rating
  isRatingSubmitLoading: false,
  isRatingSubmitted: false,
  submitRating: async (callback, rating) => {
    set({ isRatingSubmitLoading: true });
    set({ openRatingForm: false });

    const { paymentData, invoiceData } = get().paymentRes;
    const { paymentMethod, paymentType, ...invoiceFields } = invoiceData;
    let response;
    console.log("Print payment type: ", paymentType)
    // if(paymentType==='pbrc'){
    //   response = await sendRating({
    //     rating: rating.rating,
    //     comment: rating.comment.trim(),
    //     retrievalReferenceNumber: paymentData.processorInformation.retrievalReferenceNumber,
    //     paymentType: paymentType,
    //   }, true);
    // }else{
      response = await sendRating({
        rating: rating.rating,
        comment: rating.comment.trim(),
        retrievalReferenceNumber: paymentData.processorInformation.retrievalReferenceNumber,
        paymentType: paymentType,
      });
   // }
    
    set({ isRatingSubmitLoading: false });

    if (response) {
      set({ isRatingSubmitted: true });
      return callback(response);
    }
  },

  // Email receipt
  isEmailLoading: false,
  emailReceipt: async (callback, targetEmail) => {
    set({ isEmailLoading: true });

    const { paymentData, invoiceData } = get().paymentRes;
    const { paymentMethod, paymentType, ...invoiceFields } = invoiceData;

    const body = {
      invoice: {
        ...invoiceFields,
        maskedValueCC: get().maskedValueCC || '',
        cardType: get().cardType || '',
      },
      paymentResponse: {
        id: paymentData.id,
        transactionId: paymentData.processorInformation.transactionId,
        submitTimeUtc: paymentData.submitTimeUtc.toLocaleString(),
        authorizedAmount: paymentData.orderInformation.amountDetails.authorizedAmount,
        status: paymentData.status,
        retrievalReferenceNumber: paymentData.processorInformation.retrievalReferenceNumber,
      },
      targetEmail: targetEmail,
      paymentType: paymentType,
      paymentMethod: paymentMethod,
    };

    let response;
    console.log("Print payment type: ", paymentType)
    // if(paymentType === 'pbrc'){
    //   response = await sendReceiptEmail(body, true);
    // }else{
      response = await sendReceiptEmail(body);
   // }

    set({ isEmailLoading: false });
    if (response) return callback(response);
  },

  resetValidatePayerAuthData: () => {
    set({ validatePayerAuthData: null });
  },

  resetPaymentStore: () => {
    set({
      // Card details
      maskedValueCC: '',
      cardType: '',
      cardDetails: {},

      captureContext: {},

      // Transient token
      transientTokenJWT: '',
      transientTokenError: {},

      // Process payment
      isPaymentProcessing: false,
      paymentRes: null,
      paymentError: null,
      showPaymentFormErrors: false,

      // Payer auth
      isPayerAuthenticating: false,
      payerAuthData: null,
      payerAuthError: null,

      // Payer auth enrolment check
      enrollData: null,
      isPayerAuthIFrameEnabled: false,

      // Validate payer auth
      validatePayerAuthData: null,

      // Generate receipt
      isReceiptLoading: false,

      // Email receipt
      isEmailLoading: false,
    });
  },

  resetPaymentStoreOnClose: () => {
    useActiveTransactionStore.getState().resetTransaction();
    get().resetPaymentStore();
  },
}));

// export const useInvoicePaymentStore = create((set, get) => ({
//   activeStep: 'step1',
//   setActiveStep: (step) => set({ activeStep: step }),

//   handleInvoicePaymentSuccess: async () => {
//     usePaymentStore.getState().resetValidatePayerAuthData();
//   },
// }));
