import { create } from 'zustand';

export const useNavStore = create((set, get) => ({
  isNavigationOpen: false,
  isInternational: false,
  setInternational: (international) => {
    set({
      isInternational: international,
    });
  },
  toggleNav: () => {
    set({
      isNavigationOpen: !get().isNavigationOpen,
    });
  },

  openNav: () => {
    set({
      isNavigationOpen: true,
    });
  },

  closeNav: () => {
    set({
      isNavigationOpen: false,
    });
  },
}));

export default useNavStore;
