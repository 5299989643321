import { isJwtExpired } from 'jwt-check-expiration';
import { create } from 'zustand';

import { AuthState } from '@helper/hooks/useAuth';
import { getBearerToken as fetchBearerToken } from '@helper/service/token';
import useNavStore from '@store/nav';

interface AuthStoreStates {
  isInitializing: boolean;
  bearerToken: string;
  error: string;

  initializeAuth: () => Promise<string | null>;
  awaitAuthInit: () => Promise<boolean>;
  getBearerToken: (callback?: Function) => Promise<string>;

  userAuthState: AuthState | undefined;

  setUserAuthState: (userAuthState: AuthState) => void;
}

export const useAuthStore = create<AuthStoreStates>()((set, get) => ({
  isInitializing: true,
  bearerToken: '',
  error: '',

  userAuthState: undefined,

  initializeAuth: async () => {
    const isInternational = useNavStore.getState().isInternational;
    let bearerToken;
    if(isInternational){
      bearerToken = await fetchBearerToken(true);
    }else{
      bearerToken = await fetchBearerToken();
    }
    

    if (!bearerToken) {
      set({
        isInitializing: false,
        bearerToken: '',
        error: 'cannot fetch bearer token',
      });
      return null;
    }

    set({ isInitializing: false, bearerToken, error: '' });
    return bearerToken;
  },

  awaitAuthInit: async () => {
    while (!get().error && get().isInitializing) {
      await new Promise((r) => setTimeout(r, 50));
    }

    if (get().error) return false;

    return true;
  },

  getBearerToken: async (callback) => {
    let token = get().bearerToken;
    if (!token || isJwtExpired(token)) {
      try {
        const newBearerToken = await get().initializeAuth();

        if (callback) callback(newBearerToken);
        return newBearerToken;
      } catch (err) {
        console.log(err);

        if (callback) callback(undefined);
        return undefined;
      }
    } else {
      if (callback) callback(token);
      return token;
    }
  },

  setUserAuthState: (authState) => {
    set({
      userAuthState: authState,
    });
  },
}));

export default useAuthStore;
