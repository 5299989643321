import { paymentAPI } from "./api";
import { handleErrorMessage } from "./shared";

export const postValidateRecaptcha = async (recaptchaToken, international=false) => {
  try {
    let url = `/hppapi/validate-recaptcha`
    if(international){
      url = `/intapi/hppapi/validate-recaptcha`
    }
    const { data } = await paymentAPI.post(url, { recaptchaToken });
    return data;
  } catch (error) {
    return handleErrorMessage(error);
  }
};
