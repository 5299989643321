import axios from 'axios';

import { handleErrorMessage } from './shared';

const AUTH_TOKEN_ENDPOINT = `${process.env.REACT_APP_INVOICE_API_BASE_URL}/oauth2/token`;

export async function getBearerToken(international = false) {
  try {
    let url= AUTH_TOKEN_ENDPOINT
    if(international){
      url = `${process.env.REACT_APP_INVOICE_API_BASE_URL}/intapi/oauth2/token`;
    }
    const { data } = await axios.get(url);

    try {
      const token = JSON.parse(data.token);
      const access_token = token.access_token;

      return access_token;
    } catch (error) {
      return handleErrorMessage('Invalid access token');
    }
  } catch (error) {
    return handleErrorMessage(error);
  }
}
